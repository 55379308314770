import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ClassworkMixin extends Vue {
  get searchText() {
    return this.$store.state.classwork.searchText || '';
  }

  set searchText(value: string) {
    this.$store.commit('classwork/setSearchText', value);
  }

  get selectedRows() {
    return this.$store.state.classwork.selectedRows || [];
  }

  set selectedRows(value: Array<any>) {
    this.$store.commit('classwork/setSelectedRows', value);
  }

  get hasSelectedRows() {
    return CommonUtils.isNotEmpty(this.selectedRows);
  }

  get editMode() {
    return this.$store.state.classwork.editMode;
  }

  set editMode(value: boolean) {
    this.$store.commit('classwork/setEditMode', value);
  }

  get showScoreWeightDialog() {
    return this.$store.state.classwork.showScoreWeightDialog;
  }

  set showScoreWeightDialog(v: boolean) {
    this.$store.commit('classwork/setShowScoreWeightDialog', v);
  }

  get filterSelectionsSelectedClass() {
    return this.$store.state.classwork.filterSelections.selectedClass;
  }

  set filterSelectionsSelectedClass(v: any) {
    this.$store.commit('classwork/setFilterSelectionsSelectedClass', v);
  }

  get filterOptionsClassList() {
    return this.$store.state.classwork.filterOptions.classList;
  }

  set filterOptionsClassList(v: Array<any>) {
    this.$store.commit('classwork/setFilterOptionsClassList', v);
  }

  get filterOptionsShowUnitFilter() {
    return this.$store.state.classwork.filterOptions.showUnitFilter;
  }

  set filterOptionsShowUnitFilter(v: boolean) {
    this.$store.commit('classwork/setFilterOptionsShowUnitFilter', v);
  }

  get filterSelectionsSelectedUnit() {
    return this.$store.state.classwork.filterSelections.selectedUnit;
  }

  set filterSelectionsSelectedUnit(v: any) {
    this.$store.commit('classwork/setFilterSelectionsSelectedUnit', v);
  }

  get filterOptionsUnitList() {
    return this.$store.state.classwork.filterOptions.unitList;
  }

  set filterOptionsUnitList(v: Array<any>) {
    this.$store.commit('classwork/setFilterOptionsUnitList', v);
  }

  get filterSelectionsShowClassworkTypes() {
    return this.$store.state.classwork.filterSelections.showClassworkTypes;
  }

  set filterSelectionsShowClassworkTypes(v: Array<any>) {
    this.$store.commit('classwork/setFilterSelectionsShowClassworkTypes', v);
  }

  get filterSelections() {
    return this.$store.state.classwork.filterSelections;
  }

  set filterSelections(v: any) {
    this.$store.commit('classwork/setFilterSelections', v);
  }

  get filterOptions() {
    return this.$store.state.classwork.filterOptions;
  }

  set filterOptions(v: any) {
    this.$store.commit('classwork/setFilterOptions', v);
  }

  created() {
    this.editMode = false;
    this.searchText = '';
    this.selectedRows = [];
    this.showScoreWeightDialog = false;
  }
}
